import React, { Component } from 'react';
import AuthContext from './auth-context';

import { authLib } from '../../connect-react-lib/authentication';
import {
  apolloClient,
  getSelectedTag,
  logout,
  getUserRole,
} from '../../connect-react-lib';
import { GET_TAG } from '../../api/queries/tags';
import { getLanguageForCommonApps } from '../../utils';
import Intercom from '@intercom/messenger-js-sdk';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const extractTags = async (tag) => {
  try {
    const res = await apolloClient.query({
      query: GET_TAG,
      variables: { id: tag },
    });
    if (!res.data || !res.data.tag) return {};

    const tagParents = res.data.tag.tagParents;
    let organization, country, area;
    Object.entries(tagParents).map(([id, tagObj]) => {
      tagObj.id = id; // add id to tag object.
      switch (tagObj.type) {
        case 'organization':
          organization = tagObj;
          break;
        case 'country':
          country = tagObj;
          break;
        case 'area':
          area = tagObj;
          break;
        default:
          break;
      }
    });
    return { organization, country, area, selected: res.data.tag };
  } catch (e) {
    if (e.message && e.message === 'GraphQL error: not authorized') {
      await logout();
      return {};
    } else {
      console.error(e);
    }
  }
};

const componentFn = (condition, loginRoute, selectOrganizationRoute, roles) => (
  WrappedComponent
) =>
  class WithAuth extends Component {
    constructor(props) {
      super(props);
      this.state = {
        tags: {
          organization: null,
          country: null,
          area: null,
          selected: null,
        },
      };
    }

    componentDidMount() {
      if (condition) {
        this.unsubscibe = authLib.onAuthStateChanged(async (authUser) => {
          const selectedTag = getSelectedTag();

          if (!condition(authUser)) {
            if (process.env.REACT_APP_COMMONAPPS_IN_USE === 'true') {
              window.location = `/apps/`;
            } else this.props.history.push(loginRoute);
          } else {
            const userRole = await getUserRole(authUser);
            const aclRole =
              userRole && userRole.acl.find((a) => a.path === selectedTag);

            if (roles && !roles.includes(aclRole.role)) {
              console.log(
                'User role do not allow to acces this page',
                roles,
                userRole
              );
              this.props.history.push('/');
            } else {
              let tags;
              if (selectedTag) {
                tags = await extractTags(selectedTag);
                this.setState({ tags });
              }
              if (!authUser?.email.includes('livion')) {
                const intercomData = cookies.get('x-intercom');
                if (
                  intercomData &&
                  selectedTag &&
                  authUser &&
                  process.env.REACT_APP_INTERCOM_APP_ID
                ) {
                  const organization = tags?.organization;
                  console.log(
                    'App effect intercom init',
                    intercomData,
                    organization
                  );
                  Intercom({
                    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                    email: authUser?.email,
                    user_hash: intercomData?.hash,
                    name: authUser?.displayName || '',
                    language_override: 'en',
                    company: {
                      id: organization?.id,
                      name: organization?.name,
                    },
                    hide_default_launcher: false,
                  });
                }
              }
              const validTag = tags && tags.organization ? true : false;
              if (
                selectOrganizationRoute &&
                !validTag &&
                selectOrganizationRoute !== this.props.history.location.path
              ) {
                console.log(
                  'no valid organisation, forwarding to /selectorganization'
                );
                if (process.env.REACT_APP_COMMONAPPS_IN_USE === 'true') {
                  window.localStorage.clear();

                  const lng = getLanguageForCommonApps(this.props.i18n);
                  window.location.href = `/apps/${lng}/auth/select-organization`;
                } else {
                  this.props.history.push(selectOrganizationRoute);
                }
              }
            }
          }
        });
      }
    }
    componentWillUnmount() {
      if (this.unsubscibe) this.unsubscibe();
    }
    render() {
      return (
        <AuthContext.Consumer>
          {(value) =>
            value.ready ? (
              <WrappedComponent
                auth={value}
                {...this.props}
                tagObjects={this.state.tags}
                extractTags={extractTags}
              />
            ) : null
          }
        </AuthContext.Consumer>
      );
    }
  };

export const withRouteAuth = componentFn;

export const withAuth = (c) => componentFn(null, '')(c);
